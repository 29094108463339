import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="ContentPage">
        <div className="MainPageSection Inflate">
        <h1>NOT FOUND</h1>
        <p>Apologies, this page doesnt exist.</p>
      </div>
      </div>
   
  </Layout>
)

export default NotFoundPage
